<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Loan Repayments</h2>
      </div>
    </div>

    <div class="border border-solid border-blue-200 rounded overflow-hidden">
      <datatable
        :url="url"
        :ajax="true"
        :ajaxPagination="true"
        :exportable="true"
        :index="true"
        :reverse-index="true"
        :data="repayments"
        :columns="columns"
        :loading="loading"
        :onClick="click"
        :query="searchQuery"
        :actions="actions"
        :pageDetails="true"
        :rangeable="dateRange"
        ref="table"
      >
        <template #header>
          <div class="pt-4">
            <search-form
              placeholder="Search users names, emails, phone numbers, bvn, etc."
              class="mb-5"
              v-model="searchQuery"
              @submit="reloadTable"
            />
            <div class="flex justify-between">
              <div class="flex items-start flex-wrap xl:flex-no-wrap">
                <div class="flex flex-wrap items-center w-full">
                  <span class="processor-filter-title">Filters:</span>
                  <template v-for="(filter, index) in filters">
                    <span
                      v-html="filter.title"
                      :key="index"
                      class="processor-filter-item"
                      :class="{ active: currentFilter === filter }"
                      @click.prevent="toggleFilter(filter)"
                    />
                  </template>
                </div>
              </div>
              <div class="flex flex-wrap items-center">
                <!-- <span class="processor-filter-title">Exports:</span> -->
                <button
                  class="btn btn-purple-outline btn-sm mb-2 mr-2"
                  @click.prevent="$refs.table.exportAll()"
                >
                  <ion-icon name="cloud-download-outline" class="text-base" />
                  <span class="ml-2">Export All Results</span>
                </button>
                <button
                  class="btn btn-blue-outline btn-sm mb-2"
                  @click.prevent="$refs.table.exportData()"
                >
                  <ion-icon name="cloud-download-outline" class="text-base" />
                  <span class="ml-2">Export Filtered Results</span>
                </button>
              </div>
            </div>
          </div>
        </template>
      </datatable>
    </div>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="confirmChargeModal"
      @close="removeError"
    >
      <h4 class="text-2xl font-bold mb-16">Confirm Charge</h4>

      <div>
        <template v-if="chargeError">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon mr-3">!</span>
            <span>{{ chargeError }}</span>
          </div>
        </template>
        <template v-if="selectedRepayment">
          <p class="text-gray-500">Name</p>
          <div class="text-xl font-bold">
            {{ selectedRepayment.user.name }}
            {{ selectedRepayment.user.last_name }}
          </div>

          <p class="text-gray-500 mt-4">Amount awaiting confirmation</p>
          <div class="bg-blue-200 rounded p-2 text-center mb-4">
            <div class="text-xl font-bold">
              ₦{{ totalProcessed | currency }}
            </div>
          </div>

          <p class="text-gray-500 mt-2">Amount to be charged</p>
          <div class="bg-blue-200 rounded p-2 text-center mb-10">
            <div class="text-xl font-bold">
              ₦{{ amountToCharge | currency }}
            </div>
          </div>
        </template>

        <div class="text-center">
          <button
            @click.prevent="chargeNow"
            class="button bg-blue-500 text-white hover:bg-blue-600"
          >
            <!-- <template>Requesting...</template> -->
            <template v-if="loading">Charging...</template>
            <template v-else>Confirm</template>
          </button>
        </div>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="partialDebitModal"
      @close="removeError"
    >
      <h4 class="text-2xl font-bold mb-16">Confirm Partial Debit Request</h4>

      <div class="flex flex-col gap-4">
        <template v-if="chargeError">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon mr-3">!</span>
            <span>{{ chargeError }}</span>
          </div>
        </template>
        <template v-if="selectedRepayment">
          <div>
            <p class="text-gray-500">Name</p>
            <div class="text-xl font-bold">
              {{ selectedRepayment.user.name }}
              {{ selectedRepayment.user.last_name }}
            </div>
          </div>

          <p>
            This process will try to debit as close as possible to the repayment
            amount from the users' bank account
          </p>

          <div>
            <p class="text-gray-500 mt-2">Amount to be attempted</p>
            <div class="bg-blue-200 rounded py-2 px-8 mb-10">
              <div class="text-xl font-medium">
                ₦{{ amountToCharge | currency }}
              </div>
            </div>
          </div>

          <div>
            <p
              class="text-12 mt-2 font-normal text-marketplace-blue text-opacity-50"
            >
              Select a debit card.
            </p>
            <div
              class="grid grid-cols-1 gap-4 mb-3 mt-8"
              style="letter-spacing: 0.1em; max-height: 10rem; overflow: scroll"
            >
              <template v-for="(card, index) in cards">
                <div
                  :key="index"
                  class="flex flex-col"
                  @click.prevent="selectCard(card)"
                >
                  <div class="flex justify-between mb-4 cursor-pointer">
                    <h5
                      class="text-10 md:text-14 font-normal text-gray-800 inline-flex items-center"
                    >
                      <img
                        :src="getProvider(card.card_type).logo"
                        alt="Visa"
                        style="height: 10px"
                      />
                      <span
                        class="ml-5 text-gray-700"
                        v-html="`•••• &nbsp;${card.last4}`"
                      ></span>
                    </h5>
                    <div
                      class="rounded-full justify-center items-center flex p-1"
                      :class="
                        selectedCard === card
                          ? `bg-${getProvider(card.card_type).color}-500`
                          : 'border border-gray-300'
                      "
                    >
                      <ion-icon
                        name="checkmark-outline"
                        class="text-white text-lg"
                      ></ion-icon>
                    </div>
                  </div>
                  <div
                    class="w-full"
                    style="opacity: 0.04; border: 1px solid #242d4f"
                  ></div>
                </div>
              </template>
            </div>
          </div>
        </template>

        <div class="text-center">
          <button
            @click.prevent="initiatePartialDebit"
            class="button bg-blue-500 text-white hover:bg-blue-600"
            :disabled="loading"
          >
            <!-- <template>Requesting...</template> -->
            <template v-if="loading">Charging...</template>
            <template v-else>Confirm</template>
          </button>
        </div>
      </div>
    </modal>

    <modal
      className="w-full md:w-3/5 xl:w-3/10 pt-10"
      ref="fractionalChargeModal"
      @close="removeError"
    >
      <h4 class="text-2xl font-bold mb-16">Fractional Charge</h4>

      <div>
        <template v-if="chargeError">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon mr-3">!</span>
            <span>{{ chargeError }}</span>
          </div>
        </template>
        <template v-if="selectedRepayment">
          <p class="text-gray-500 mb-2">Name</p>
          <div class="text-xl font-bold">
            {{ selectedRepayment.user.name }}
            {{ selectedRepayment.user.last_name }}
          </div>

          <p class="text-gray-500 mt-4 mb-2">Outstanding Repayment</p>
          <div class="bg-blue-200 rounded p-2 text-left mb-4">
            <div class="text-xl font-bold">
              ₦{{ amountToCharge | currency }}
            </div>
          </div>

          <p class="text-gray-500 font-black">Pay with</p>
          <p
            class="text-12 mt-2 font-normal text-marketplace-blue text-opacity-50"
          >
            Select a card to charge now.
          </p>
          <div
            class="grid grid-cols-1 gap-4 mb-3 mt-8"
            style="letter-spacing: 0.1em; max-height: 10rem; overflow: scroll"
          >
            <template v-for="(card, index) in cards">
              <div
                :key="index"
                class="flex flex-col"
                @click.prevent="selectCard(card)"
              >
                <div class="flex justify-between mb-4 cursor-pointer">
                  <h5
                    class="text-10 md:text-14 font-normal text-gray-800 inline-flex items-center"
                  >
                    <img
                      :src="getProvider(card.card_type).logo"
                      alt="Visa"
                      style="height: 10px"
                    />
                    <span
                      class="ml-5 text-gray-700"
                      v-html="`•••• &nbsp;${card.last4}`"
                    ></span>
                  </h5>
                  <div
                    class="rounded-full justify-center items-center flex p-1"
                    :class="
                      selectedCard === card
                        ? `bg-${getProvider(card.card_type).color}-500`
                        : 'border border-gray-300'
                    "
                  >
                    <ion-icon
                      name="checkmark-outline"
                      class="text-white text-lg"
                    ></ion-icon>
                  </div>
                </div>
                <div
                  class="w-full"
                  style="opacity: 0.04; border: 1px solid #242d4f"
                ></div>
              </div>
            </template>
          </div>
        </template>

        <div class="mt-4">
          <p class="mb-2 text-left text-gray-500">Charge Amount</p>
          <template>
            <form-group
              type="text"
              name="amount"
              :form="fractionalRepaymentForm"
              v-model="fractionalRepaymentForm.data.amount.value"
              :autofocus="true"
            />
          </template>
        </div>

        <div class="text-center">
          <button
            @click.prevent="chargeFractionalAmount"
            class="button bg-blue-500 text-white hover:bg-blue-600"
          >
            <!-- <template>Requesting...</template> -->
            <template v-if="loading">Charging...</template>
            <template v-else>Confirm</template>
          </button>
        </div>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="transferChargeModal"
      @close="removeError"
    >
      <h4 class="text-2xl font-bold mb-16">Confirm Transfer</h4>

      <div>
        <template v-if="chargeError">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon mr-3">!</span>
            <span>{{ chargeError }}</span>
          </div>
        </template>
        <template v-if="selectedRepayment">
          <p class="text-gray-500">Name</p>
          <div class="rounded p-2 h-12">
            <div class="text-xl font-bold">
              {{ selectedRepayment.user.name }}
              {{ selectedRepayment.user.last_name }}
            </div>
          </div>

          <p class="text-gray-500">Amount</p>
          <div class="bg-blue-200 rounded p-2 text-center mb-10">
            <div class="text-xl font-bold">
              ₦{{ amountToCharge | currency }}
            </div>
          </div>
        </template>

        <div class="text-center">
          <button
            @click.prevent="transfer"
            class="button bg-blue-500 text-white hover:bg-blue-600"
            :disabled="false"
          >
            <template v-if="loading">Charging...</template>
            <template v-else>Confirm</template>
          </button>
        </div>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="markAsUnpaidModal"
      @close="removeError"
    >
      <h4 class="text-2xl font-bold mb-16">Confirm Update</h4>

      <div>
        <template v-if="chargeError">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon mr-3">!</span>
            <span>{{ chargeError }}</span>
          </div>
        </template>
        <template v-if="selectedRepayment">
          <p class="text-gray-500">Name</p>
          <div class="rounded p-2 h-12">
            <div class="text-xl font-bold">
              {{ selectedRepayment.user.name }}
              {{ selectedRepayment.user.last_name }}
            </div>
          </div>

          <p class="text-gray-500">Amount</p>
          <div class="bg-blue-200 rounded p-2 text-center mb-10">
            <div class="text-xl font-bold">
              ₦{{ amountToCharge | currency }}
            </div>
          </div>
        </template>

        <div class="text-center">
          <button
            @click.prevent="markLoanAsUnpaid"
            class="button bg-blue-500 text-white hover:bg-blue-600"
            :disabled="false"
          >
            <template v-if="loading">Updating...</template>
            <template v-else>Confirm</template>
          </button>
        </div>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="settlementModal"
      @close="removeError"
    >
      <h4 class="text-2xl font-bold mb-16">Confirm Settlement</h4>

      <div>
        <template v-if="chargeError">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon mr-3">!</span>
            <span>{{ chargeError }}</span>
          </div>
        </template>
        <template v-if="selectedRepayment">
          <p class="text-gray-500">Name</p>
          <div class="rounded p-2 h-12">
            <div class="text-xl font-bold">
              {{ selectedRepayment.user.name }}
              {{ selectedRepayment.user.last_name }}
            </div>
          </div>

          <p class="text-gray-500">Amount</p>
          <div class="bg-blue-200 rounded p-2 text-center mb-10">
            <div class="text-xl font-bold">
              ₦{{ amountToCharge | currency }}
            </div>
          </div>

          <form @submit.prevent="confirmSettlement">
            <form-group
              type="select"
              :options="settlementOptions"
              name="settlementStatus"
              v-model="settlementForm.data.settlementStatus.value"
              :form="settlementForm"
            >
              Settlement Status
            </form-group>
          </form>
        </template>

        <div class="text-center">
          <button
            @click.prevent="confirmSettlement"
            class="button bg-blue-500 text-white hover:bg-blue-600"
            :disabled="false"
          >
            <template v-if="loading">Settling...</template>
            <template v-else>Confirm</template>
          </button>
        </div>
      </div>
    </modal>

    <modal
      className="w-full md:w-3/5 xl:w-4/10 pt-10"
      ref="commentModal"
      @close="removeError"
    >
      <h4 class="text-xl font-bold mb-2">Loan Comment</h4>
      <h6 v-if="selectedRepayment" class="text-sm font-bold mb-6">
        {{ selectedRepayment.user.name }} {{ selectedRepayment.user.last_name }}
      </h6>
      <div class="flex-grow mb-4">
        <form>
          <form-group
            type="textarea"
            name="comment"
            :form="repaymentComment"
            v-model="repaymentComment.data.body.value"
          >
            Comments.
          </form-group>

          <button
            type="button"
            class="btn btn-sm btn-blue ml-auto"
            :disabled="repaymentComment.loading"
            @click.prevent="makeComment"
          >
            <span v-if="repaymentComment.loading">Saving...</span>
            <span v-else>Save</span>
          </button>
        </form>
      </div>
      <div class="max-h-30 overflow-y-scroll">
        <template v-for="(comment, i) in currentRepaymentComments">
          <div :key="i" class="border border-gray-500 mb-4">
            <div class="border-b border-gray-500 px-4 py-1 text-sm font-thin">
              {{ comment.admin.name }} {{ comment.admin.last_name }} commented
              on {{ new Date(comment.created_at).format('dd M, Y') }}
            </div>
            <div class="py-4 px-4 font-normal">{{ comment.body }}</div>
          </div>
        </template>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="successModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">Success!</div>
      <div class="text-xs mb-10">
        {{ successMessage }}
      </div>
    </modal>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  data() {
    return {
      url: `${this.$baseurl}/repayments`,
      searchQuery: '',
      currentFilter: null,
      company: null,
      loading: false,
      repayments: [],
      successMessage: '',
      settlementStatus: '',
      settlementOptions: ['settled', 'unsettled'],
      settlementForm: this.$options.basicForm([
        { name: 'settlementStatus', rules: 'required' },
      ]),
      actions: [
        {
          text: 'charge now',
          class:
            'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
          action: this.openConfirmModal,
          disabled: (loan) =>
            loan.status === 'paid' || loan.status === 'transferred',
        },
        {
          text: 'partial debit',
          class:
            'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
          action: this.openPartialDebitModal,
          disabled: (loan) =>
            loan.status === 'paid' || loan.status === 'transferred',
        },
        {
          text: 'mark as paid',
          class:
            'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
          action: this.openTransferModal,
          disabled: (loan) =>
            loan.status === 'paid' || loan.status === 'transferred',
        },
        {
          text: 'mark as unpaid',
          class:
            'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
          action: this.openUnpaidModal,
          disabled: (loan) =>
            loan.status !== 'paid' && loan.status !== 'transferred',
        },
        // {
        //   text: "settle",
        //   class:
        //     "border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200",
        //   action: this.openSettlementModal
        //   // disabled: (loan) =>
        //   //   loan.status !== "paid" && loan.status !== "transferred",
        // },
        this.canDo('credpal:can_charge_user_for_loan_fractional_repayment')
          ? {
              text: 'fractional charge',
              class:
                'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
              action: this.openFractionalChargeModal,
              disabled: (repayment) =>
                repayment.status === 'paid' ||
                repayment.status === 'transferred' ||
                repayment.status === 'processing',
            }
          : '',
      ],
      columns: [
        {
          name: 'user',
          th: 'Staff',
          render: (repayment, user) => {
            if (!user) {
              return 'N/A';
            }
            return `${user.name} ${user.last_name}`;
          },
        },
        {
          name: 'user',
          th: 'Phone No',
          render: (repayment, user) => {
            if (!user) {
              return 'N/A';
            }
            return `${user.phone_no}`;
          },
        },
        {
          name: 'loan',
          th: 'Amount Disbursed',
          render: (repayment) => {
            if (!repayment) {
              return 'N/A';
            }
            return `<span className="text-blue-300">₦ ${this.$options.filters.currency(
              repayment?.loan?.loan_amount || 0
            )}</span>`;
          },
        },
        {
          name: 'due_date',
          th: 'Due Date',
          render: (repayment, due_date) =>
            moment(due_date).format('MMM Do YYYY'),
        },
        {
          name: 'loan',
          th: 'Loan Tenure',
          render: (repayment, loan) => {
            if (!loan) {
              return 'N/A';
            }
            return loan?.tenure;
          },
        },
        {
          name: 'loan',
          th: 'Interest Rate',
          render: (repayment, loan) => {
            if (!loan?.interest_rate) {
              return 'N/A';
            }
            return `${loan?.interest_rate}%`;
          },
        },
        {
          name: 'amount',
          th: 'Amount',
          render: (repayment, amount) =>
            `<span className="text-blue-300">₦ ${this.$options.filters.currency(
              amount
            )}</span>`,
        },
        {
          name: 'amount_paid',
          th: 'Amount Paid',
          render: (repayment, amount) =>
            `<span className="text-blue-300">₦ ${this.$options.filters.currency(
              amount
            )}</span>`,
        },
        {
          name: 'default_charge',
          th: 'Default Charge',
          render: (repayment) =>
            `<span className="text-blue-300">₦ ${this.$options.filters.currency(
              repayment.default_charge?.amount || 0
            )}</span>`,
        },
        {
          name: 'status',
          th: 'Status',
          render: (repayment) => {
            const today = moment().format('YYYY-MM-DD');
            switch (repayment.status) {
              case 'paid':
                return `<div class="badge badge-green-soft-outline">
  										Paid
  									</div>`;
              case 'transferred':
                return `<div class="badge badge-green-soft-outline">
  										Transferred
  									</div>`;
              case 'not_due':
                if (today > repayment.due_date) {
                  return `<div class="badge badge-orange-soft-outline">
  										Due
  									</div>`;
                }
                return `<div class="badge badge-blue-soft-outline">
  										Not Due
  									</div>`;
              case 'due':
                if (repayment.default_charge) {
                  return `<div class="badge badge-red-soft-outline">
                      Defaulting
                    </div>`;
                }
                return `<div class="badge badge-orange-soft-outline">
  										Due
  									</div>`;
              case 'processing':
                return `<div class="badge badge-blue-soft-outline">
  										Processing
                    </div>`;
              case 'failed':
                return `<div class="badge badge-red-soft-outline">
  										Failed
  									</div>`;
              case 'pending':
                return `<div class="badge badge-orange-soft-outline">
  										Pending
                    </div>`;
              default:
                if (repayment.defaulted) {
                  return `<div class="badge badge-red-soft-outline">
  											Default
  										</div>`;
                }
                break;
            }
          },
        },
        {
          name: 'payment_method',
          th: 'Payment Method',
          render: (repayment) => {
            if (!repayment?.payment_method) {
              return 'N/A';
            }
            return repayment?.payment_method;
          },
        },
        {
          name: 'settlement_status',
          th: 'Settlement Status',
          render: (repayment) => {
            switch (repayment?.settlement_status) {
              case 'settled':
                return `<div class="badge badge-green-soft-outline">
  										Settled
  									</div>`;
              case 'unsettled':
                return `<div class="badge badge-red-soft-outline">
                    Unsettled
                  </div>`;
              default:
                return `<div class="badge badge-blue-soft-outline">
                      Pending
                  </div>`;
            }
          },
        },
        {
          name: 'id',
          th: 'Repayment ID',
          render: (repayment) => repayment?.id,
        },
        {
          name: 'loan',
          th: 'Disburse Date',
          render: (repayment, loan) => {
            if (!loan?.disbursed_at) {
              return 'N/A';
            }
            return moment(loan.disbursed_at).format('MMM Do YYYY, h:mm:ss a');
          },
        },
        {
          className: 'w-1/10',
          name: 'comment',
          th: 'Comments',
          render: (repayment) => {
            return `
                  <div class="flex items-center">
                    <button
                      class="btn btn-blue btn-sm mr-5"
                      data-click="click(${repayment.id})"
                    >
                      View
                    </button>
                  </div>
                `;
          },
        },
      ],
      selectedRepayment: null,
      chargeError: null,
      filters: [
        { title: 'Not Due', name: 'not_due' },
        { title: 'Due', name: 'due' },
        { title: 'Transferred', name: 'transferred' },
        { title: 'Paid', name: 'paid' },
        { title: 'Processing', name: 'processing' },
        { title: 'Failed', name: 'failed' },
        { title: 'Pending', name: 'pending' },
      ],
      repaymentComment: this.$options.basicForm(['body']),
      currentRepaymentComments: [],
      commentsLoading: false,
      fractionalRepaymentForm: this.$options.basicForm([
        { name: 'amount', rules: 'required|numeric|min:10' },
      ]),
      selectedCard: null,
      providers: {
        mastercard: {
          logo: require('@/assets/mastercard.svg'),
          color: 'red',
        },
        visa: {
          logo: require('@/assets/visa-blue.svg'),
          color: 'blue',
        },
        default: {
          logo: require('@/assets/credit-card.svg'),
          color: 'gray',
        },
      },
      dateRange: {
        value: {
          startDate: null,
          endDate: null,
        },
        format: 'dd/mm/yyyy',
      },
    };
  },
  computed: {
    amountToCharge() {
      let amount = this.selectedRepayment?.amount || 0;
      return (
        amount +
        (this.selectedRepayment?.default_charge?.amount || 0) -
        (this.selectedRepayment?.repayment_wallet?.total_paid || 0) -
        (this.selectedRepayment?.totalProcessed || 0) -
        (this.selectedRepayment?.amount_paid || 0)
      );
    },
    canChargeUsers() {
      return this.canDo('credpal:can_charge_users_card');
    },
    cards() {
      return this.selectedRepayment?.user?.active_repayment_cards;
    },
    totalProcessed() {
      return this.selectedRepayment?.totalProcessed || 0;
    },
  },
  beforeMount() {
    this.getRepayments();
    this.showActions();
  },
  mounted() {},
  methods: {
    showActions() {
      if (!this.canChargeUsers) {
        this.actions = [];
        return;
      }
    },
    toggleFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }

      this.$refs.table.clickedFilter(this.currentFilter);
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    openConfirmModal(loan) {
      this.selectedRepayment = loan;
      this.$refs.confirmChargeModal.open();
    },
    openFractionalChargeModal(loan) {
      this.selectedRepayment = loan;
      this.$refs.fractionalChargeModal.open();
    },
    openPartialDebitModal(loan) {
      this.selectedRepayment = loan;
      this.$refs.partialDebitModal.open();
    },
    openTransferModal(loan) {
      this.selectedRepayment = loan;
      this.$refs.transferChargeModal.open();
    },
    openUnpaidModal(loan) {
      this.selectedRepayment = loan;
      this.$refs.markAsUnpaidModal.open();
    },
    openSettlementModal(loan) {
      this.selectedRepayment = loan;
      this.$refs.settlementModal.open();
    },
    removeError() {
      this.chargeError = '';
    },
    openCommentModal(repayment) {
      this.selectedRepayment = repayment;
      this.getRepaymentComments(repayment.id);
      this.$refs.commentModal.open();
    },
    click(defaultUser, type = 'user') {
      if (type === 'user') {
        this.openCommentModal(defaultUser);
        return;
      }
      this.$router.push({
        name: 'staff-view',
        params: { staffId: defaultUser?.user_id },
      });
    },
    selectCard(card) {
      this.selectedCard = card;
    },
    getProvider(cardType) {
      switch (cardType?.toLowerCase()?.trim()) {
        case 'mastercard':
        case 'mastercard credit':
        case 'mastercard debit':
          return this.providers.mastercard;
        case 'visa':
        case 'visa credit':
        case 'visa debit':
          return this.providers.visa;
        default:
          return this.providers.default;
      }
    },
    async getRepayments() {
      this.loading = true;
      await this.$get({
        url: `${this.$baseurl}/repayments`,
        headers: this.headers,
        success: (response) => {
          this.repayments = response.data.repayments;
        },
      });
      this.loading = false;
    },
    async chargeNow() {
      let amount = this.selectedRepayment?.amount;
      amount += this.selectedRepayment?.default_charge?.amount || 0;
      amount -= this.selectedRepayment?.repayment_wallet?.total_paid || 0;
      amount -= this.selectedRepayment?.totalProcessed || 0;

      this.loading = true;
      await this.$post({
        url: `${this.$baseurl}/repayments/charge`,
        headers: this.headers,
        data: {
          user_id: this.selectedRepayment.user_id,
          authorization_code:
            this.selectedRepayment.user.repayment_card.authorization_code,
          email: this.selectedRepayment.user.email,
          loan_id: this.selectedRepayment.loan.id,
          amount,
          repayment_id: this.selectedRepayment.id,
        },
        success: () => {
          this.reloadTable();
          this.$refs.confirmChargeModal.close();
          this.selectedRepayment = null;
          this.successMessage = 'Payment Successfull';
          this.$refs.successModal.open();
        },
        error: (error) => {
          this.chargeError = error.response.data.message;
          this.successMessage = '';
        },
      });
      this.loading = false;
    },
    async transfer() {
      let amount = this.selectedRepayment?.amount;
      amount += this.selectedRepayment?.default_charge?.amount || 0;

      this.loading = true;
      await this.$post({
        url: `${this.$baseurl}/repayments/update-repayment`,
        headers: this.headers,
        data: {
          user_id: this.selectedRepayment.user_id,
          loan_id: this.selectedRepayment.loan.id,
          amount,
          repayment_id: this.selectedRepayment.id,
        },
        success: () => {
          this.reloadTable();
          this.$refs.transferChargeModal.close();
          this.selectedRepayment = null;
          this.successMessage = 'Payment Successfull';
          this.$refs.successModal.open();
        },
        error: (error) => {
          this.chargeError = error.response.data.message;
          this.successMessage = '';
        },
      });
      this.loading = false;
    },
    async markLoanAsUnpaid() {
      let amount = this.selectedRepayment?.amount;
      amount += this.selectedRepayment?.default_charge?.amount || 0;

      this.loading = true;
      await this.$post({
        url: `${this.$baseurl}/repayments/update-repayment/unpaid`,
        headers: this.headers,
        data: {
          user_id: this.selectedRepayment.user_id,
          loan_id: this.selectedRepayment.loan.id,
          amount,
          repayment_id: this.selectedRepayment.id,
        },
        success: () => {
          this.reloadTable();
          this.$refs.markAsUnpaidModal.close();
          this.selectedRepayment = null;
          this.successMessage = 'Update Successfull';
          this.$refs.successModal.open();
        },
        error: (error) => {
          this.chargeError = error.response.data.message;
          this.successMessage = '';
        },
      });
      this.loading = false;
    },
    async makeComment() {
      this.repaymentComment.loading = true;

      await this.$post({
        url: `${this.$baseurl}/repayments/comment`,
        data: {
          repaymentId: this.selectedRepayment?.id,
          body: this.repaymentComment.data.body.value,
        },
        headers: this.headers,
        success: () => {
          // this.getStatementComments();
          this.repaymentComment = this.resetForm(this.repaymentComment);
          this.successMessage = 'Comment saved successfully.';
          this.getRepaymentComments(this.selectedRepayment?.id);
          this.$refs.successModal.open();
        },
        error: (error) => {
          this.successMessage = '';
          this.repaymentComment.error = error;
        },
      });
      this.repaymentComment.loading = false;
    },
    async getRepaymentComments(repaymentId) {
      this.commentsLoading = true;

      await this.$get({
        url: `${this.$baseurl}/repayments/comment/${repaymentId}`,
        headers: this.headers,
        success: (response) => {
          this.currentRepaymentComments = response.data.comments;
        },
        error: (error) => {
          this.repyamentError.error = error;
        },
      });
      this.commentsLoading = false;
      await this.$root.loadSession();
    },
    async confirmSettlement() {
      if (!this.validateForm(this.settlementForm)) {
        return false;
      }
      this.loading = true;
      await this.sendRequest(
        'admin.repayments.settle',
        this.selectedRepayment?.id,
        {
          data: {
            settlement_status: this.settlementForm.data.settlementStatus.value,
          },
          success: () => {
            this.reloadTable();
            this.$refs.settlementModal.close();
            this.selectedRepayment = null;
            this.successMessage = 'Settlement Successfull';
            this.$refs.successModal.open();
          },
          error: () => {
            this.chargeError = 'Settlement Failed';
          },
        }
      );
      this.loading = false;
    },
    async chargeFractionalAmount() {
      this.chargeError = '';
      if (!this.validateForm(this.fractionalRepaymentForm)) {
        return false;
      }
      if (
        this.fractionalRepaymentForm.data.amount.value > this.amountToCharge
      ) {
        this.chargeError =
          'The amount you are charging is greater than the outstanding balance.';
        return false;
      }
      if (!this.selectedCard) {
        this.chargeError = 'Please select a card to charge.';
        return false;
      }
      this.chargeError = '';
      this.loading = true;
      await this.sendRequest(
        'admin.repayments.fractionalPayment',
        this.selectedRepayment?.id,
        {
          data: {
            amount: parseFloat(this.fractionalRepaymentForm.data.amount.value),
            repaymentCardId: this.selectedCard.id,
          },
          success: () => {
            this.reloadTable();
            this.$refs.fractionalChargeModal.close();
            this.selectedRepayment = null;
            this.selectedCard = null;
            this.successMessage = 'Payment Successfull';
            this.$refs.successModal.open();
          },
          error: (error) => {
            this.chargeError =
              error?.response?.data?.message || 'Payment Unsuccessful';
          },
        }
      );
      this.loading = false;
    },
    async initiatePartialDebit() {
      if (!this.selectedCard) {
        return this.chargeError = 'Please select a debit card to process.';
      }
      this.chargeError = '';
      this.loading = true;
      await this.sendRequest(
        'admin.repayments.partialDebit',
        this.selectedRepayment?.id,
        {
          data: {
            repayment_card_id: this.selectedCard.id,
          },
          success: () => {
            this.reloadTable();
            this.$refs.partialDebitModal.close();
            this.selectedRepayment = null;
            this.selectedCard = null;
            this.successMessage = 'Payment Successfull';
            this.$refs.successModal.open();
          },
          error: (error) => {
            this.chargeError =
              error?.response?.data?.message || 'Debit Unsuccessful';
          },
        }
      );
      this.loading = false;
    },
  },
};
</script>

<style lang="sass">
.processor-select-input
  @apply bg-white border border-blue-200 duration-300 font-medium px-6 py-3 relative rounded-sm text-gray-600
  font-size: 13px
  &:hover
    @apply bg-blue-200 border-blue-200 text-blue-900
  &:focus-within
    @apply border-blue-500 text-blue-500
.processor-filter-title
  @apply font-bold mb-2 mr-2 text-sm uppercase w-full
.processor-filter-item
  @apply bg-white border cursor-pointer font-bold mb-2 mr-2 px-2 py-2 rounded-sm text-xs text-gray-600 tracking-wide uppercase
  &:hover
    @apply bg-blue-100 border-blue-500 text-blue-500
  &.active
    @apply bg-blue-500 border-blue-500 text-white
</style>
